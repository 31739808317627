import firebase from 'gatsby-plugin-firebase';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () => {
  if (typeof window !== 'undefined') {
    return isBrowser() && window.localStorage.getItem('user')
      ? JSON.parse(window.localStorage.getItem('user'))
      : {};
  }
  return {};
};

export const setAndUpdateUser = (user) => {
  if (typeof window !== 'undefined') {
    return (
      isBrowser() && window.localStorage.setItem('user', JSON.stringify(user))
    );
  }
  return {};
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.email;
};
export const hasIntro = (userMeta) => {
  return !userMeta.intro;
};

export const setUser = (user) => {
  if (typeof window !== 'undefined') {
    return (
      isBrowser() && window.localStorage.setItem('user', JSON.stringify(user))
    );
  }
  return {};
};
export const setAndUpdateUserFromAuthState = (user) => {
  if (typeof window !== 'undefined') {
    if (user) {
      firebase
        .firestore()
        .collection('usersData')
        .where('uid', '==', user.uid)
        .get()
        .then((userCollection) => {
          if (userCollection.empty) {
            const userMetaData = {
              uid: user.uid,
              position: '',
              favoriteCategories: [],
              roles: ['user'],
              intro: false,
              displayName: user.displayName ? user.displayName : '',
              email: user.email ? user.email : '',
              phoneNumber: user.phoneNumber ? user.phoneNumber : '',
              photoURL: user.photoURL ? user.photoURL : '',
              lastSignInTime:
                user && user.metadata && user.metadata.lastSignInTime
                  ? user.metadata.lastSignInTime
                  : '',
              creationTime:
                user && user.metadata && user.metadata.creationTime
                  ? user.metadata.creationTime
                  : '',
            };
            firebase
              .firestore()
              .collection('usersData')
              .add(userMetaData);
          } else {
            const userDataFromApi = userCollection.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            });
            setUser({
              uid: user.uid,
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              tel: userDataFromApi[0].tel,
              address: userDataFromApi[0].address,
              age: userDataFromApi[0].age,
            });
          }
        });

      // if (!user.emailVerified) {
      //   user.sendEmailVerification().then(() => {
      //     console.log('email verification send');
      //   });
      // }
    } else {
      setUser({});
    }
  }
};

export const logout = (callback) => {
  if (typeof window !== 'undefined') {
    return new Promise((resolve) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          setUser({});
          resolve();
          callback();
        });
    });
  }
  return {};
};
