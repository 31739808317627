import React, { createContext, useState } from 'react';

export const AppStateContext = createContext();

const getState = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('state') || 'nachbarschaft';
  }
};

const AppStateProvider = ({ children }) => {
  const [appValue, setAppValue] = useState(getState());

  const setAppState = (value) => {
    if (value === 'nachbarschaft') {
      setAppValue('nachbarschaft');
    }
    if (value === 'work') {
      setAppValue('work');
    }
    if (value === 'seelsorge') {
      setAppValue('seelsorge');
    }
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('state', value);
    }
  };
  const getAppStateFromStorage = () => {
    let state;
    if (typeof window !== 'undefined') {
      state = window.localStorage.getItem('state');
      if (state) {
        return state;
      }
    }
    return state;
  };
  const getAppColors = () => {
    if (appValue === 'nachbarschaft') {
      return '#36679c';
    }
    if (appValue === 'work') {
      return '#f3c813';
    }
    if (appValue === 'seelsorge') {
      return '#fd1d15';
    }
    if (!appValue) {
      return '#36679c';
    }
  };

  return (
    <AppStateContext.Provider
      value={{
        appValue,
        setAppState,
        getAppStateFromStorage,
        getAppColors,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
