const website = require('./website');

module.exports = {
  de: {
    default: false,
    path: 'de',
    locale: 'de-de',
    siteLanguage: 'de',
    ogLang: 'de_DE',
    defaultTitle: website.siteTitle,
    defaultTitleAlt: website.siteTitleAlt,
    defaultDescription:
      'Ich bin seit fast 10 Jahren selbstständig als Trainer, Web Entwickler und Frontend Architekt und helfe meinen Kunden Projekte in den Bereichen technische Beratung, App- und Webentwicklung zu realisieren.',
    home: 'Home',
    aboutme: 'Über mich',
    agb: 'AGB',
    impressum: 'Impressum',
    datenschutz: 'Datenschutz',
    services: 'Leistungen',
    webdev: 'Webentwicklung',
    appdev: 'App Entwicklung',
    webconsulting: 'Technische Beratung',
    portfolio: 'Portfolio',
    kontakt: 'Kontakt',
    blog: 'Blog',
    headline: 'Schreiben und Veröffentlichen für LekoArts',
    category: 'Kategorie',
    categories: 'Kategorien',
    was: 'wurde',
    were: 'wurden',
    tagged: 'markiert mit',
    recent: 'Neue',
    projects: 'Projekte',
    allCategories: 'Alle Kategorien',
    entries: 'Einträge',
    contact: {
      name: 'Name',
      prename: 'Vorname',
      personalInformation: 'Persönliche Informationen',
      confirmation:
        'Ich stimmt der <a href="/de/datenschutz/" target="_blank">Datenschutzerklärung</a> zu',
      privacyLink: 'datenschutz',
      subject: 'Thema',
      subjectQuestion: 'Bitte wähle ein Thema aus.',
      text: 'Text',
      email: 'Email',
      send: 'Abschicken',
      success:
        'Juhu die Mail ging raus! Ich melde mich bei dir sobald ich sie gelesen haben.',
      error:
        'Du kannst mich auch einfach <a href="mailto:hallo@marcrqadziwill.com">hier</a> erreichen!',
    },
    newsletter: {
      headline: 'Bleibe up to date!',
      description:
        'Melde dich für meinen gratis Newsletter an und erhalte deine Dosis technische Bildung und Inspiration.',
      subscribe: 'Newsletter abonnieren',
      button: 'Anmelden',
      confirmation: 'Anmeldung bestätigen',
      privacy:
        'Ich stimmt der <a href="/de/datenschutz/" target="_blank">Datenschutzerklärung</a> zu.',
      success: 'Juhu es hat geklappt',
    },
    workshopNewsletter: {
      headline: 'Bleibe up to date!',
      description:
        'Melde dich für meinen gratis Newsletter an und erhalte deine Dosis technische Bildung und Inspiration.',
      subscribe: 'Newsletter abonnieren',
      button: 'Anmelden',
      confirmation: 'Anmeldung bestätigen',
      privacy:
        'Ich stimmt der <a href="/de/datenschutz/" target="_blank">Datenschutzerklärung</a> zu.',
      success: 'Juhu es hat geklappt',
    },
    sharepage: 'Seite teilen auf',
    success: 'Juhu',
    error: 'Ups, es ist etwas schief gegangen.',
    errorRequired: 'Bitte gebe hier einen Wert an.',
    urlTranslations: {
      home: {
        title: 'Home',
        link: '/',
      },
      // '404': {
      //   title: '404',
      //   link: '404',
      // },
      'dev-404-page': {
        title: 'dev-404-page',
        link: 'dev-404-page',
      },
      aboutme: {
        title: 'Über mich',
        link: 'ueber-mich',
      },
      privacy: {
        title: 'Datenschutz',
        link: 'datenschutz',
      },
      imprint: {
        title: 'Impressum',
        link: 'impressum',
      },
      // terms: {
      //   title: 'AGB',
      //   link: 'agb',
      // },
      contact: {
        title: 'Kontakt',
        link: 'kontakt',
      },
      faq: {
        title: 'FAQ',
        link: 'faq',
      },
      contactsended: {
        title: 'Versendet',
        link: 'kontakt/nachricht-versendet',
      },
      newslettersended: {
        title: 'Abonniert',
        link: 'angemeldet',
      },
      // blog: {
      //   title: 'blog',
      //   link: 'blog',
      // },
      devcademy: {
        title: 'devcademy',
        link: 'devcademy/javascript-workshops',
      },
      courses: {
        title: 'JavaScript Online-Kurse',
        link: 'devcademy/javascript-online-kurse',
      },
      workshops: {
        title: 'JavaScript Workshops',
        link: 'devcademy/javascript-workshops',
      },
      services: {
        title: 'Leistungen',
        link: 'leistungen',
      },
      webdevelopment: {
        title: 'Webentwicklung',
        link: 'leistungen/webentwicklung',
      },
      webconsulting: {
        title: 'Technische Beratung',
        link: 'leistungen/webentwicklung-technische-beratung',
      },
      appdevelopment: {
        title: 'Appentwicklung',
        link: 'leistungen/app-entwicklung',
      },
    },
  },
  en: {
    default: true,
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.siteTitle,
    defaultTitleAlt: website.siteTitleAlt,
    defaultDescription:
      'I have been self-employed as a trainer, web developer, and frontend architect for almost 10 years and help my clients realize great projects in the fields of technical consulting, app, and web development.',
    home: 'Home',
    aboutme: 'about me',
    agb: 'terms and conditions',
    services: 'services',
    impressum: 'imprint',
    datenschutz: 'privacy',
    webdev: 'en Webentwicklung',
    appdev: 'en App Entwicklung',
    webconsulting: 'en Technische Beratung',
    portfolio: 'en portfolio',
    kontakt: 'contact',
    blog: 'blog',
    headline: website.headline,
    category: 'Category',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'tagged with',
    recent: 'Recent',
    projects: 'projects',
    allCategories: 'All categories',
    entries: 'entries',
    contact: {
      name: 'name',
      prename: 'prename',
      personalInformation: 'personal information',
      confirmation:
        'I agree to the <a href="/privacy/" target="_blank">privacy policy</a>.',
      privacyLink: 'datenschutz',
      subject: 'subject',
      subjectQuestion: 'Please select a topic.',
      text: 'text',
      email: 'email',
      send: 'send',
      success:
        "Juhu your mail is on it's way. I'll get back to you as soon as I read it.",
      error:
        'You can also just contact me over <a href="mailto:hallo@marcrqadziwill.com">here</a>.',
    },
    newsletter: {
      headline: 'stay up to date!',
      description:
        'Sign up for my free newsletter and get your dose of technical education and inspiration.',
      subscribe: 'Join the Newsletter',
      button: 'Subscribe',
      confirmation: 'Confirm subscription',
      privacy:
        'I agree to the <a href="/privacy/" target="_blank">privacy policy</a>.',
      success: 'Yesss it is done',
    },
    workshopNewsletter: {
      headline: 'stay up to date with my courses and workshops!',
      description:
        'Sign up for my free newsletter and get your dose of technical education and inspiration.',
      subscribe: 'Join the Newsletter',
      button: 'Subscribe',
      confirmation: 'Confirm subscription',
      privacy:
        'I agree to the <a href="/privacy/" target="_blank">privacy policy</a>.',
      success: 'Yesss it is done',
    },
    sharepage: 'share page on',
    success: 'Juhu',
    error: 'Somthing went wrong',
    errorRequired: 'please provide correct data',
    urlTranslations: {
      home: {
        title: 'home',
        link: '/',
      },
      '404': {
        title: '404',
        link: '404',
      },
      'dev-404-page': {
        title: 'dev-404-page',
        link: 'dev-404-page',
      },
      aboutme: {
        title: 'about me',
        link: 'about-me',
      },
      privacy: {
        title: 'privacy',
        link: 'privacy',
      },
      imprint: {
        title: 'imprint',
        link: 'imprint',
      },
      // terms: {
      //   title: 'terms',
      //   link: 'terms',
      // },
      contact: {
        title: 'contact',
        link: 'contact',
      },
      faq: {
        title: 'faq',
        link: 'faq',
      },
      contactsended: {
        title: 'Send',
        link: 'contact/mail-send',
      },
      newslettersended: {
        title: 'Abonniert',
        link: 'subscribed',
      },
      blog: {
        title: 'blog',
        link: 'blog',
      },
      services: {
        title: 'services',
        link: 'services',
      },
      devcademy: {
        title: 'devcademy',
        link: 'devcademy/javascript-workshops',
      },
      courses: {
        title: 'javascript online courses',
        link: 'devcademy/javascript-online-courses',
      },
      workshops: {
        title: 'javascript workshops',
        link: 'devcademy/javascript-workshops',
      },
      webdevelopment: {
        title: 'webdevelopment',
        link: 'services/webdevelopment',
      },
      webconsulting: {
        title: 'webconsulting',
        link: 'services/webdevelopment-consulting',
      },
      appdevelopment: {
        title: 'appdevelopment',
        link: 'services/app-development',
      },
    },
  },
};
