/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/normalize.scss';
import './src/styles/animate.css';
import './src/styles/general.scss';
import CustomLayout from './wrapPageElement';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
export const wrapPageElement = CustomLayout;
// export const onServiceWorkerUpdateReady = () => window.location.reload(true);
