import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import FullWidthBox from 'components/FullWidthBox';
import ResponsiveGrid from 'components/Layouts/ResponsiveGrid';
import StyledBox from 'components/StyledBox';

const Footer = () => {
  return (
    <footer
      css={css`
        a {
          color: unset;
        }
        ul {
          list-style: none;
        }
        position: fixed;
        width: 100%;
        bottom: 0;
      `}
    >
      <StyledBox
        style={{ color: '#ffffff' }}
        background="brand"
        pad={{
          left: 'large',
          right: 'large',
          top: 'none',
          bottom: 'none',
        }}
      >
        <FullWidthBox pad="none">
          <ResponsiveGrid>
            <StyledBox
              css={css`
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                  display: block;
                  font-weight: 400;
                }
                margin-top: 20px;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                @media only screen and (min-width: 640px) {
                  margin-top: 0;
                  -webkit-align-items: flex-start;
                  -webkit-box-align: flex-start;
                  -ms-flex-align: flex-start;
                  align-items: flex-start;
                }
              `}
            >
              <span className="text medium" style={{ fontWeight: 'bold' }}>
                &#169; togethere {new Date().getFullYear()}
              </span>
            </StyledBox>

            <StyledBox
              css={css`
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                  display: block;
                  font-weight: 400;
                }
                margin-top: 20px;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                @media only screen and (min-width: 640px) {
                  margin-top: 0;
                  -webkit-align-items: flex-end;
                  -webkit-box-align: flex-end;
                  -ms-flex-align: flex-end;
                  align-items: flex-end;
                }
              `}
            >
              <ul
                css={css`
                  margin: 0;
                  padding: 0;
                  list-style: none;
                  li {
                    display: block;
                    font-weight: 400;
                    a {
                      color: #ffffff !important;
                    }
                  }
                `}
              >
                <li
                  style={{
                    display: 'inline-block',
                    marginLeft: '0',
                  }}
                >
                  <span className="text">
                    <Link to="/impressum/">Impressum</Link>
                  </span>
                </li>

                <li
                  style={{
                    display: 'inline-block',
                    marginLeft: '20px',
                  }}
                >
                  <span className="text">
                    <Link to="/datenschutzerklaerung/">
                      Datenschutzerklärung
                    </Link>
                  </span>
                </li>
              </ul>
            </StyledBox>
          </ResponsiveGrid>
        </FullWidthBox>
      </StyledBox>
    </footer>
  );
};
export default React.memo(Footer);
