import React from 'react';
import Layout from './src/components/Layout';
import AppStateProvider from 'state/appstate';

const wrapPageElement = ({ element, props }) => {
  return (
    <AppStateProvider>
      <Layout {...props}>{element}</Layout>
    </AppStateProvider>
  );
};
export default wrapPageElement;
