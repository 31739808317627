import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { css } from '@emotion/core';
import NavigationHeader from 'components/NavigationHeader';
import StyledBox from 'components/StyledBox';
import Footer from 'components/Footer';
// import { AppStateContext } from 'state/appstate';

const Layout = ({ children, pageContext: { pageEntry }, location }) => {
  // const { setAppState, getAppStateFromStorage } = React.useContext(
  //   AppStateContext,
  // );

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
          }
        }
      }
    }
  `);
  // React.useEffect(() => {
  //   setAppState(getAppStateFromStorage());
  // });
  return (
    <div
      css={css`
        display: grid;
        box-sizing: border-box;
        grid-template-areas:
          'header header'
          'main main'
          'footer footer';
        grid-template-columns: 1fr;
        grid-gap: 0px 0px;
        grid-template-rows: auto auto auto;
        // display: flex;
        // align-items: center;
        // flex-direction: column;
      `}
    >
      {typeof google === undefined && (
        <Helmet>
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB3CdFFztYBRkJlxlazc1yp7K3ym7ZDxB0&libraries=geometry`}
            async
            defer
          />
        </Helmet>
      )}
      <StyledBox
        css={css`
          grid-area: header;
        `}
      >
        <header>
          <NavigationHeader
            items={data.site.siteMetadata.menuLinks}
            location={location.pathname}
            locationEntry={pageEntry}
          />
        </header>
      </StyledBox>
      <div
        css={css`
          grid-area: main;
          width: 100%;
        `}
      >
        <main
          css={css`
            position: relative;
            // height: calc(100vh - 25px);
            // margin-top: 3.75rem;
          `}
        >
          {children}
        </main>
      </div>
      <StyledBox
        css={css`
          grid-area: footer;
        `}
      >
        <Footer />
      </StyledBox>
    </div>
  );
};
export default Layout;
