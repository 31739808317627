import i18n from 'utils/i18n';
import { without } from 'lodash';

const translateMetaMenuItem = (item, locale) => {
  const lang = i18n[locale];
  let link = item.link;
  if (!lang.default) {
    if (lang.pageTranslations[item.link] === undefined) return '';
    link = `${locale}/${lang.pageTranslations[item.link]}`;
  }
  return {
    name: i18n[locale][item.name],
    link: `/${link}/`,
  };
};

const scrollToAnchor = (anchor) => {
  if (document.getElementById(anchor)) {
    window.scrollTo({
      top: document.getElementById(anchor).offsetTop - 60,
      left: 0,
      behavior: 'smooth',
    });
    // scroll(confi)
  }
};

// const scroll = (config) => {
//   window.scrollTo(config);
// }

// let defaultLocale = 'de';
// if (navigator.language === ('en' || 'en_EN' || 'en_US' || 'en_GB')) {
//   defaultLocale = 'en';
// }
// console.log(navigator.language);
// console.log(defaultLocale);
// const removeTrailingSlashes = (string) => {
//   if (string !== '/') {
//     string = string.replace(/^\/|\/$/g, '');
//   }
//   return string;
// };

const handleNotDefaultLanguages = (url, locale) => {
  let translatedPath = '';

  if (url === '/') {
    translatedPath = `/${locale}/`;
  } else {
    translatedPath = `/${locale}/${url}/`;
  }
  return translatedPath;
};

const isBrowserLocaleSameAsCurrentLocale = (currentLocale) => {
  let browserLocale = '';
  if (navigator.language === ('en' || 'en-EN' || 'en-US' || 'en-GB')) {
    browserLocale = 'en';
  }

  if (navigator.language === 'de-DE' || navigator.language === 'de') {
    browserLocale = 'de';
  }
  return currentLocale !== browserLocale;
};

const translateUrl = (name, locale) => {
  if (locale === undefined) {
    locale = 'en';
  }

  let currentLang = i18n[locale];
  let translationObject = currentLang.urlTranslations[name];

  if (!translationObject) {
    currentLang = i18n.en;
    translationObject = currentLang.urlTranslations[name];
    if (!translationObject) {
      return { link: '/404/', title: 'Error' };
    }
  }
  const url = translationObject.link;
  const title = translationObject.title;
  let translatedPath = '';

  if (currentLang.default) {
    if (url === '/') {
      translatedPath = url;
    } else {
      translatedPath = `/${url}/`;
    }
  } else {
    translatedPath = handleNotDefaultLanguages(url, locale);
  }

  return {
    link: translatedPath,
    title,
  };
};

const translate = (name, locale) => {
  const currentLang = i18n[locale];

  return currentLang[name] ? currentLang[name] : 'no translation';
};

const getTranslations = (locationEntry, locale) => {
  const translations = [];
  const localeEntries = without(Object.keys(i18n), locale);
  localeEntries.map((langKey) => {
    const translationsFromConfig = i18n[langKey].urlTranslations;
    if (translationsFromConfig && translationsFromConfig[locationEntry]) {
      let translatedPath = '';

      const url = translationsFromConfig[locationEntry].link;
      if (i18n[langKey].default) {
        if (url === '/') {
          translatedPath = url;
        } else {
          translatedPath = `/${url}/`;
        }
      } else {
        translatedPath = handleNotDefaultLanguages(url, langKey);
      }
      translations.push({
        langKey: langKey.toUpperCase(),
        url: translatedPath,
      });
    }
    return true;
  });
  return translations;
};

const getFormValues = (formNode) => {
  return Object.getOwnPropertyNames(formNode.elements).reduce((obj, key) => {
    const formControl = formNode.elements[key];
    const name = formControl.getAttribute('name');
    if (name && !obj[name]) {
      obj[name] = formControl.value;
    }
    return obj;
  }, {});
};

const categories = {
  walkthedog: {
    value: 'walkthedog',
    text: 'Tierbetreuung / Gassi gehen',
    color: '#3cb8cf',
  },
  shopping: {
    value: 'shopping',
    text: 'Einkäufe / Erledigungen',
    color: '#cf9d3c',
  },
  doc: {
    value: 'doc',
    text: 'Apotheke / Arzt',
    color: '#533ccf',
  },
  childcare: {
    value: 'childcare',
    text: 'Kinderbetreuung',
    color: '#cf543c',
  },
  education: {
    value: 'education',
    text: 'Bildung / Homeschooling',
    color: '#6ecf3c',
  },
  repairs: {
    value: 'repairs',
    text: 'Kleine Reparaturen',
    color: '#cf3c6e',
  },
  agriculture: {
    value: 'agriculture',
    text: 'Erntehelfer / Landwirtschaft',
    color: '#cf3c6e',
  },
  gardening: {
    value: 'gardening',
    text: 'Gartenarbeiten',
    color: '#cf9d3c',
  },
};

const categoriesWork = {
  computer: {
    value: 'computer',
    text: 'IT',
    color: '#3cb8cf',
  },
  film: {
    value: 'film',
    text: 'Film & Foto',
    color: '#3cb8cf',
  },
  gluehbirne: {
    value: 'gluehbirne',
    text: 'Kreation',
    color: '#3cb8cf',
  },
  datum: {
    value: 'datum',
    text: 'Projektmanagement',
    color: '#3cb8cf',
  },
  schreiben: {
    value: 'schreiben',
    text: 'Konzeption/ Text',
    color: '#3cb8cf',
  },
  network: {
    value: 'network',
    text: 'Support / Network',
    color: '#cf3c6e',
  },
  agriculture: {
    value: 'agriculture',
    text: 'Erntehelfer / Landwirtschaft',
    color: '#3cb8cf',
  },
};
const categoriesSeelsorge = {
  seelsorge: {
    value: 'seelsorge',
    text: 'Seelsorge',
    color: '#3cb8cf',
  },
};

export { categories, categoriesWork, categoriesSeelsorge, scrollToAnchor };
