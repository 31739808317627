module.exports = {
  siteTitle: 'Mintme', // Navigation and Site Title
  siteTitleAlt: 'The personal website of Marc Radziwill', // Alternative Site title for SEO
  siteTitleShort: 'mintme', // short_name for manifest
  siteUrl: 'https://mintme.is', // Domain of your site. No trailing slash!
  siteLanguage: 'de', // Language Tag on <html> element
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription: 'mintme',
  minibio: `
  marcradziwill.
  `,
  pathPrefix: '/',
  author: 'Mintme', // Author for schemaORGJSONLD
  email: 'info@mintme.is', // Author for schemaORGJSONLD
  organization: 'mintme',
  streetNumber: 'Straße 1',
  zipCity: '87437 Kempten (Allgäu)',
  userTwitter: '@mintme', // Twitter Username
  ogSiteName: 'Mintme', // Facebook Site Name
  ogLanguage: 'de_US',
  // Manifest and Progress color
  themeColor: '#B7DEDA',
  backgroundColor: '#ffffff',

  // Social component
  facebook: 'https://twitter.com/mintme/',
  twitter: 'https://twitter.com/mintme/',
  instagram: 'https://twitter.com/mintme/',
  twitterHandle: '@mintme',
  youtube: 'https://www.youtube.com/channel/xxx',
  googlemaps:
    'https://www.google.com/maps/place/Marc+Radziwill/@47.7313385,10.3290423,15z/data=!4m2!3m1!1s0x0:0x1368d4f8f4565ce?sa=X&ved=2ahUKEwji7sOLpOzkAhUGa8AKHSqlBKIQ_BIwC3oECAwQCA',
};
