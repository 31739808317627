/* eslint-disable complexity */
import React from 'react';
import { Link, navigate } from 'gatsby';
import { css } from '@emotion/core';
import IconMenu from 'components/IconMenu';
import ExternalLink from 'components/ExternalLink';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { Avatar, Menu, Dropdown } from 'antd';
import { media } from 'utils/media';
import { isLoggedIn, logout, getUser } from 'utils/auth';
import { AppStateContext } from 'state/appstate';

const NavigationHeader = () => {
  const { appValue, setAppState, getAppColors } = React.useContext(
    AppStateContext,
  );
  const appColor = getAppColors();
  const user = getUser();

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/app/profile/">Profil</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <ExternalLink
          onClick={() => {
            logout(() => {
              navigate('/');
            });
          }}
        >
          Logout
        </ExternalLink>
      </Menu.Item>
    </Menu>
  );

  const menuApp = (
    <Menu>
      <Menu.Item key="1">
        <ExternalLink
          onClick={() => {
            setAppState('nachbarschaft');
            if (typeof window !== 'undefined') {
              window.location.reload();
            }
          }}
        >
          Nachbarschaftshilfe
        </ExternalLink>
      </Menu.Item>
      <Menu.Item key="2">
        <ExternalLink
          onClick={() => {
            setAppState('work');
            if (typeof window !== 'undefined') {
              window.location.reload();
            }
          }}
        >
          Business
        </ExternalLink>
      </Menu.Item>
      {/* <Menu.Item key="3">
        <ExternalLink
          onClick={() => {
            setAppState('seelsorge');
          }}
        >
          Seelsorge
        </ExternalLink>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <div
      css={css`
        position: fixed;
        min-height: 3.75rem;
        left: 0;
        right: 0;
        z-index: 5;
        @media ${media.small} {
          position: fixed;
        }
      `}
    >
      <div
        css={css`
          background: transparent;
          background-color: #ffffff;
          ${typeof window === 'undefined'
            ? ''
            : `border-bottom: 1px solid ${appColor};`}
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          margin: 0 auto;
          padding-left: 1rem;
          padding-right: 1rem;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
          height: 100%;
          min-height: 3.75rem;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1;
            z-index: -1;
          }
        `}
      >
        <Link
          css={css`
            align-items: center;
            display: flex;
            flex-shrink: 0;
            text-decoration: none;
            color: #3c6ed0;
          `}
          to="/"
          aria-label="Back to homepage"
        >
          {typeof window !== 'undefined' && (
            <>
              {(appValue === 'nachbarschaft' || appValue === undefined) && (
                <>
                  <img
                    css={css`
              width: 220px;
              margin: 0;
              display: none;
              @media${media.small} {
                display: flex;
              }
            `}
                    src="/togethere-nachbarschaft.png"
                    alt="logo"
                  />
                  <img
                    css={css`
              width: 70px;
              margin: 0;
              display: flex;
              @media${media.small} {
                display: none;
              }
            `}
                    src="/togethere-nachbarschaft-mobile.png"
                    alt="logo"
                  />
                </>
              )}
              {appValue === 'work' && (
                <>
                  <img
                    css={css`
              width: 220px;
              margin: 0;
              display: none;
              @media${media.small} {
                display: flex;
              }
            `}
                    src="/togethere-work.png"
                    alt="logo"
                  />
                  <img
                    css={css`
              width: 70px;
              margin: 0;
              display: flex;
              @media${media.small} {
                display: none;
              }
            `}
                    src="/togethere-work-mobile.png"
                    alt="logo"
                  />
                </>
              )}
              {appValue === 'seelsorge' && (
                <>
                  <img
                    css={css`
              width: 220px;
              margin: 0;
              display: none;
              @media${media.small} {
                display: flex;
              }
            `}
                    src="/togethere-seelsorge.png"
                    alt="logo"
                  />
                  <img
                    css={css`
              width: 70px;
              margin: 0;
              display: flex;
              @media${media.small} {
                display: none;
              }
            `}
                    src="/togethere-seelsorge-mobile.png"
                    alt="logo"
                  />
                </>
              )}
            </>
          )}
        </Link>
        {isLoggedIn() && (
          <Dropdown
            css={css`
              margin-left: 0px;
              @media ${media.small} {
                margin-left: 20px;
              }
              width: 100px;
              min-width: 80px;
            `}
            overlay={menuApp}
          >
            <ExternalLink onClick={(e) => e.preventDefault()}>
              Menu <DownOutlined />
            </ExternalLink>
          </Dropdown>
        )}
        <div
          css={css`
            align-self: center;
            display: flex;
            min-height: 3.75rem;
            width: 100%;
            ul {
              display: flex;
              justify-content: center;
              width: 100%;
              margin: 0;
            }
            li {
              display: inline-block;
              padding: 10px;
              // min-height: 3.74rem;
              a {
                margin-bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
              }
            }
          `}
        >
          <IconMenu
            css={css`
              position: absolute;
              top: 100px;
              top: 3.85rem;
              left: 0;
              width: 100%;
              background: #fff;
              margin: 0;
              li {
                a {
                  ${isLoggedIn() ? '' : 'cursor:unset;'}
                  width: 40px;
                }
                padding: 5px;
                display: inline-block;
                list-style: none;
              }
              @media ${media.small} {
                position: unset;
                li {
                  display: inline-block;
                  list-style: none;
                }
              }
            `}
          />
        </div>
        <div
          css={{
            alignSelf: `flex-end`,
            display: `flex`,
            marginLeft: `auto`,
            minHeight: '3.75rem',
          }}
        >
          <div
            css={css`
              align-self: center;
              display: flex;
              min-height: 3.75rem;
              width: 100%;

              ul {
                display: flex;
                justify-content: center;
                width: 100%;
                margin: 0;
              }
              li {
                display: inline-block;
                padding: 10px;
                // min-height: 3.74rem;
                a {
                  margin-bottom: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                }
              }
            `}
          >
            <ul
              css={css`
                width: 200px !important;
              `}
            >
              {/* <li>
                <Link to="/blog/">Blog</Link>
              </li> */}
              <li>
                {typeof window !== 'undefined' && (
                  <Link
                    css={css`
                    ${(appValue === 'nachbarschaft' ||
                      appValue === undefined) &&
                      `color: #36679c !important`}
                    ${appValue === 'work' && `color: #f3c813 !important`}
                    ${appValue === 'seelsorge' && `color: #fd1d15 !important`}
                  `}
                    to="/ueber-uns/"
                  >
                    &Uuml;ber uns
                  </Link>
                )}
              </li>
            </ul>
          </div>
          {/* <SocialTeaser color={appColor} parent="Header" /> */}
          {isLoggedIn() && (
            <div
              css={css`
                margin-left: 0px;
                @media ${media.small} {
                  margin-left: 10px;
                }
                margin-top: 20px;
                margin-right: 40px;
              `}
            >
              <Dropdown overlay={menu}>
                {user.photoURL ? (
                  <Avatar
                    css={css`
                      .ant-avatar-string {
                        position: unset;
                      }
                    `}
                    src={user.photoURL}
                  />
                ) : (
                  <Avatar
                    style={{ backgroundColor: appColor }}
                    icon={<UserOutlined />}
                  />
                )}
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationHeader;
