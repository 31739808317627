module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md",".markdown"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-images","options":{"backgroundColor":"#ffffff","maxWidth":1280}},"gatsby-remark-embedder"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"#ffffff","maxWidth":1280},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mintme","short_name":"mintme","description":"mintme","start_url":"/","background_color":"#ffffff","theme_color":"#B7DEDA","display":"standalone","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"localize":[{"start_url":"/de/","lang":"de","name":"marc radziwill","short_name":"marc radziwill.","description":"Ich bin seit fast 10 Jahren selbstständig als Developer Advocate, Web Entwickler und Frontend Architekt und helfe meinen Kunden Projekte in den Bereichen technische Beratung, App- und Webentwicklung zu realisieren."}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
